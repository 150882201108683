import React, { useState, useEffect, createContext } from "react"

interface IUserInterfaceContextProviderProps {
  children: React.ReactNode
}

export interface IUserInterfaceContext {
  studenttype: string | null
  firstname: string | null
  search: string
  keyword: string
  setSearch: (search: string) => void
  setKeyword: (keyword: string) => void
}

export const UserInterfaceContext = createContext<IUserInterfaceContext>({
  studenttype: null,
  firstname: null,
  search: ``,
  keyword: ``,
  setSearch: () => {},
  setKeyword: () => {},
})

export default function UserInterfaceContextProvider({
  children,
}: IUserInterfaceContextProviderProps): JSX.Element {
  const [studenttype, setStudentType] =
    useState<IUserInterfaceContext["studenttype"]>(null)
  const [firstname, setFirstName] =
    useState<IUserInterfaceContext["firstname"]>(null)

  const [search, setSearch] = useState<IUserInterfaceContext["search"]>(``)
  const [keyword, setKeyword] = useState<IUserInterfaceContext["keyword"]>(``)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const studenttype = params.get(`studenttype`)
    const firstname = params.get(`firstname`)
    setStudentType(studenttype)
    setFirstName(firstname)
  }, [])

  return (
    <UserInterfaceContext.Provider
      value={{
        studenttype,
        firstname,
        search,
        keyword,
        setSearch,
        setKeyword,
      }}
    >
      {children}
    </UserInterfaceContext.Provider>
  )
}
